require('bootstrap-select/js/bootstrap-select.js');

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
    static targets = [ "input" ]

    connect() {
        $(this.inputTarget).selectpicker(
            // set virtualScroll to 25
            {
                virtualScroll: 25 }
        )

    }

    disconnect() {
        $(this.inputTarget).selectpicker("destroy")
    }
}