/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// Webpacker dependencies
import 'core-js'
import 'regenerator-runtime/runtime'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
Rails.start()
ActiveStorage.start()

require('jquery')
require('masonry-layout') // check if needed
require('@rails/actiontext')
require("cleave.js")

// CSS
import "tailwindcss/tailwind.css"
import "stylesheets/components/_buttons"
import "tailwindcss/utilities"
import "@tailwindcss/forms"
import "@tailwindcss/aspect-ratio"
import '../stylesheets/style.scss';

import '../src/jquery.are-you-sure';
//import '../src/jquery.bootstrap-select.min';
import '../src/jquery.dirtyforms';
import '../src/jquery.fileinput';
import '../src/jquery.metadata';
import '../src/jquery.migrate';
import '../src/jquery.mousewheel';
import '../src/jquery.nestedform';
import '../src/jquery.rrssb.min';

// required for carousel
import 'swiper/swiper-bundle.min.css'


import '../src/application_steps.js';

//import '../src/datatables/datatables.js';
//import '../src/datatables/datatables.js.coffee.erb';

// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'
// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

import '../src/form/form';
import '../src/maps/bing.js';
import '../src/tenant/tenant.js';

import '../src/bulma/components.js';

window.iziToast = require("izitoast")

iziToast.settings({
    timeout: 10000,
    resetOnHover: true,
    icon: 'fontawesome',
    transitionIn: 'fadeInUp',
    transitionOut: 'fadeOut',
    transitionInMobile: 'fadeInUp',
    transitionOutMobile: 'fadeOutDown'
})


$(document).on('turbo:load', function() {

    // assign initMap to global scope for callback from bing maps
    // because callback can not be scoped :-(
    // window.initMap = window.Application.initMap;


});

$(document).on('turbo:destroy', function() {

});


// Stimulus
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('tdropdown', Dropdown)
application.register('modal', Modal)
application.register('toggle', Toggle)

// Import AutoComplete
// https://github.com/afcapel/stimulus-autocomplete
import { Autocomplete } from 'stimulus-autocomplete/src/autocomplete'
application.register('autocomplete', Autocomplete)

// Hotwire
import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo